import { Card, Group, Stack } from '@mantine/core';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IActivityRepeat } from '~/server/services/activity/types';
import { FormCheckbox } from '../Checkbox';
import { FormChips } from '../ChipGroup';
import { FormDatePicker } from '../Dates/DatePicker';
import { FormNumberInput } from '../NumberInput';
import { FormRadio } from '../Radio';
import { FormSelect } from '../Select/Select';
import { useRepeatValues } from './repeat.values.hook';

export const FormScheduleRepeat: React.FC = () => {
  const { t } = useTranslation('dateComponent');
  const { control, formState, setValue } = useFormContext<{ repeat?: IActivityRepeat }>();
  const repeatType = useWatch({ control, name: 'repeat.type' });
  const endsType = useWatch({ control, name: 'repeat.ends.type' });
  const enabled = useWatch({ control, name: 'repeat.enabled' });
  const { repeatTypeOptions, weekDayOptions, endsTypeOptions } = useRepeatValues();

  if (!enabled) return null;

  return (
    <Card title="Repeat" bg="unset" withBorder>
      <Stack gap="xs">
        <Group align="start">
          <FormNumberInput size="xs" name="repeat.every" label={t('repeat every')} min={1} />
          <FormSelect size="xs" name="repeat.type" label={t('repeat')} data={repeatTypeOptions} />
        </Group>

        {/* Weekly Options */}
        {repeatType === 'weekly' && (
          <FormChips
            size="xs"
            name="repeat.daysOfWeek"
            label={t('days of week')}
            data={weekDayOptions}
          />
        )}

        {/* Monthly Options */}
        {repeatType === 'monthly' && (
          <FormNumberInput
            size="xs"
            name="repeat.daysOfMonth.0"
            label={t('day of month')}
            min={1}
            max={31}
          />
        )}

        {/* End Conditions */}
        <Group>
          <FormRadio size="xs" name="repeat.ends.type" label={t('ends')} data={endsTypeOptions} />
        </Group>
        {endsType === 'date' && (
          <FormDatePicker size="xs" name="repeat.ends.date" label={t('end of repeat')} />
        )}

        {endsType === 'times' && (
          <FormNumberInput size="xs" name="repeat.ends.times" label={t('end after times')} min={1} />
        )}
      </Stack>
    </Card>
  );
};

export const RepeatEnabled: React.FC = () => {
  const { t } = useTranslation('dateComponent');
  const { control, formState, setValue } = useFormContext<{ repeat?: IActivityRepeat }>();
  const enabled = useWatch({ control, name: 'repeat.enabled' });
  const handleEnableChange = useCallback(() => {
    if (enabled) {
      setValue('repeat', undefined);
    } else {
      setValue('repeat', { enabled: true, type: 'daily', every: 1, ends: { type: 'times', times: 1 } });
    }
  }, [enabled]);

  return (
    <FormCheckbox size="xs" name={'repeat.enabled'} onChange={handleEnableChange} label={t('repeat')} />
  );
};
