import { Avatar, Group, Stack, Text } from '@mantine/core';
import { forwardRef } from 'react';
import { PersonSelectItem } from '../Lookup/UserSelect';

export const EmailOptionComponent = forwardRef<HTMLDivElement, PersonSelectItem>(
  ({ label, value, avatar, ...others }: PersonSelectItem, ref) => {
    const sameValueAsLabel = value === label;
    return (
      <Stack gap={2}>
        <Group ref={ref} {...others} gap={'xs'}>
          <Avatar src={avatar} size={'xs'} name={value} />
          {label}
        </Group>
        {sameValueAsLabel ? null : (
          <Text fz={'xs'} c="dimmed">
            {value}
          </Text>
        )}
      </Stack>
    );
  },
);

EmailOptionComponent.displayName = 'EmailOptionComponent';
