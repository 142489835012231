import { ComboboxItem } from '@mantine/core';
import { useMemo, useState } from 'react';
import { useCard } from '~/Components/Card/card.hook';
import { api } from '~/trpc';
import { useMemberships } from '~/ui/hooks/organization-memberships.hook';

export const useEmailOptions = ({
  cardId,
  more = [],
}: { cardId?: string | null; more?: ComboboxItem[] }) => {
  const memberships = useMemberships();
  const { card } = useCard(cardId);
  const [emails, setEmails] = useState<ComboboxItem[]>([]);

  const { data: related = { results: [] }, isLoading } = api.cards.related.useQuery(
    { id: cardId! },
    { enabled: Boolean(cardId), staleTime: 5 * 60 * 1000, gcTime: 10 * 60 * 1000 },
  );

  const data = useMemo(() => {
    const users =
      memberships?.data?.map((member) => ({
        label: member.publicUserData.identifier,
        value: member.publicUserData.identifier,
        avatar: member.publicUserData.imageUrl ?? undefined,
      })) ?? [];

    const relatedEmails = [...(card ? [card] : []), ...related.results]
      .map(({ name, email, avatar }) => ({
        label: name,
        value: email!,
        avatar: avatar ?? undefined,
      }))
      .filter(({ value }) => typeof value === 'string');

    const all = [...emails, ...more, ...users, ...relatedEmails];

    // Remove duplicates
    return all.filter((item, index, self) => self.findIndex((t) => t.value === item.value) === index);
  }, [memberships?.data, card, related.results, emails, more]);

  return { emails, setEmails, data, isLoading };
};
