import { Avatar, Group, Indicator, Stack, Text, Tooltip, useMantineColorScheme } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { IconCheck, IconQuestionMark, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AppActionIcon } from '~/Components/Shared/Icon/AppActionIcon';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { GuestItem, RsvpStatus } from './types';

type GuestProps = {
  item: { key: string; value: string; option?: GuestItem };
  handleRemove: (val: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
};

const getMeetingStatusIconColor = (status: RsvpStatus) => {
  if (status === 'yes') return { color: 'green', Icon: IconCheck };
  if (status === 'no') return { color: 'red', Icon: IconX };
  return { color: 'gray', Icon: IconQuestionMark };
};

export const Guest: React.FC<GuestProps> = ({ item, handleRemove, disabled, readOnly }) => {
  const { t } = useTranslation('fieldsComponent');

  const { hovered, ref } = useHover();
  const { colorScheme } = useMantineColorScheme();
  const { color, Icon } = getMeetingStatusIconColor('awaiting');

  return (
    <Group px={'sm'} py={5} gap="xs" justify="space-between" ref={ref}>
      <Group gap={'xs'} px={2} py={3} h="100%" align="center">
        <Tooltip label={t('awaiting')} position="left" withArrow>
          <Indicator
            position="bottom-end"
            size={16}
            label={<AppIcon stroke={2} Icon={Icon} color={color} size={12} />}
            color={colorScheme === 'light' ? `${color}.3` : `${color}.9`}
          >
            <Avatar src={item.option?.avatar} name={item.value} size="sm" />
          </Indicator>
        </Tooltip>
        <Stack gap={2}>
          <Text size="sm" fw={500}>
            {item.option?.label ?? item.value}
          </Text>
          <Text c="dimmed" size="xs">
            {item.option?.value}
          </Text>
        </Stack>
      </Group>

      {hovered && (
        <AppActionIcon
          size={'sm'}
          tooltip="Remove"
          Icon={IconX}
          disabled={disabled ?? readOnly}
          color="red"
          onClick={(event) => {
            event.stopPropagation();
            handleRemove(item.key);
          }}
        />
      )}
    </Group>
  );
};
