import {
  Card,
  CheckIcon,
  Combobox,
  ComboboxItem,
  Group,
  ScrollArea,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useCallback, useMemo, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodUtils } from '~/utils/zod-utils';
import { FormMultiSelectProps } from '../MultiSelect/MultiSelect';
import { useMultiSelectSearch } from '../MultiSelect/multi-select-search.hook';
import { DisplayOptions } from '../shared/DisplayOptions';
import { Label } from '../shared/Label';
import { parseFormErrorMessage } from '../shared/error-message.util';
import { selectUtils } from '../shared/select.utils';
import { EmailGuestListSummary } from './EmailGuestListSummary';
import { EmailOptionComponent } from './EmailOptionComponent';
import { Guest } from './Guest';
import { useEmailOptions } from './email-options.hook';
import { GuestItem } from './types';

type EmailGuestListProps = Omit<FormMultiSelectProps, 'data'> & {
  more?: ComboboxItem[];
  cardId?: string | null;
};

export const EmailGuestList: React.FC<EmailGuestListProps> = ({ name, more = [], cardId, ...props }) => {
  const { t } = useTranslation('fieldsComponent');
  const { control, getValues } = useFormContext<Record<string, string[]>>();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });
  const {
    field: { onChange },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const errorMessage = parseFormErrorMessage(error?.message);

  const { setEmails, data: originalData } = useEmailOptions({ cardId, more });

  const [data, setData] = useState<GuestItem[]>(originalData);

  const onCreate = useCallback((item: string) => {
    const email = zodUtils.emailSchema.safeParse(item);
    if (email.success) {
      setEmails((current) => [...current, { label: item, value: item }]);

      return item;
    }
    return false;
  }, []);

  const onFailCreation = useCallback(() => {
    notifications.show({
      message: t('Invalid email'),
      color: 'red',
    });
  }, [t]);

  const { search, setSearch, handleValueSelect } = useMultiSelectSearch({
    name,
    onCreate,
    onChange,
    setData,
    onFailCreation,
  });

  const exactOptionMatch = data.some((item) => item.label === search);

  const handleRemove = useCallback((val: string) => {
    const values: string[] = getValues(name);

    onChange(values.filter((v) => v !== val));
  }, []);

  const currentValues: string[] = getValues(name) ?? [];

  const valuesList = useMemo(
    () =>
      currentValues.map((item) => {
        const option = data.find((option) => option.value === item);
        return {
          key: item,
          value: option?.label ?? item,
          option,
        };
      }),
    [currentValues, data],
  );

  const options = useMemo(
    () =>
      data
        .filter((item) => selectUtils.filterFn(item, search))
        .map((item) => (
          <Combobox.Option
            value={item.value}
            key={item.value}
            active={currentValues.includes(item.label)}
          >
            <Group gap="sm">
              {currentValues.find((v) => v === item.value) ? <CheckIcon size={12} /> : null}
              <EmailOptionComponent {...item} />
            </Group>
          </Combobox.Option>
        )),
    [currentValues, data, search],
  );

  const createLabel = useMemo<string>(() => `+ ${t('Add an email')} ${search}`, [search, t]);

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false} {...props}>
      <Combobox.DropdownTarget>
        <TextInput
          id={name}
          label={
            props.label ? (
              <Label label={props.label} description={props.description} required={props.required} />
            ) : undefined
          }
          error={errorMessage}
          placeholder={t('Select or add emails...')}
          value={search}
          onChange={(event) => {
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          readOnly={props.disabled ?? props.readOnly}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
        />
      </Combobox.DropdownTarget>
      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="auto">
            <DisplayOptions
              search={search}
              options={options}
              createLabel={createLabel}
              exactOptionMatch={exactOptionMatch}
              creatable={true}
            />
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
      <EmailGuestListSummary values={valuesList} />
      <Card withBorder px={2} py={0}>
        <ScrollArea.Autosize h={150} type="auto">
          <Combobox.Options>
            {valuesList.length ? (
              valuesList.map((item) => (
                <Guest
                  key={item.key}
                  item={item}
                  handleRemove={handleRemove}
                  disabled={props.disabled}
                  readOnly={props.readOnly}
                />
              ))
            ) : (
              <Combobox.Empty>{t('No guests added')}</Combobox.Empty>
            )}
          </Combobox.Options>
        </ScrollArea.Autosize>
      </Card>
    </Combobox>
  );
};
