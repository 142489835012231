import { Group, Stack, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from '~/utils/basic';
import { GuestItem, RsvpStatus } from './types';

export const EmailGuestListSummary: React.FC<{
  values: { key: string; value: string; option: GuestItem | undefined }[];
}> = ({ values }) => {
  const { t } = useTranslation('fieldsComponent');

  const groups = useMemo(() => {
    return utils.groupBy(
      values.map(({ option }) => ({
        status: option?.status ?? 'awaiting',
      })),
      'status',
    );
  }, [values]);

  return (
    <Stack gap={2}>
      <Text size="sm">{`${values.length} ${t('Guests')}`}</Text>
      <Group gap={2}>
        {Object.keys(groups).map((item) => (
          <Group key={item} gap={2}>
            <Text c="dimmed" size="xs">{`${groups[item]?.length} ${t(item as RsvpStatus)}`}</Text>
          </Group>
        ))}
      </Group>
    </Stack>
  );
};
