import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid, Group, Modal, Stack, Text } from '@mantine/core';
import { IconAlarm, IconAlertTriangle, IconClock, IconTimeDuration30 } from '@tabler/icons-react';
import { useCallback, useEffect, useMemo } from 'react';
import { Form, FormProvider, type SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCard } from '~/Components/Card/card.hook';
import { useCardTypes } from '~/Components/Cards/card-types.hook';
import { FormDateTimePicker } from '~/Components/Form/Dates/DateTimePicker';
import { EmailGuestList } from '~/Components/Form/EmailGuestLlist/EmailGuestList';
import { FormInput } from '~/Components/Form/Input/Input';
import { FormCardSelector } from '~/Components/Form/Lookup/FormCardSelectors';
import { FormScheduleRepeat, RepeatEnabled } from '~/Components/Form/Repeat/Repeat';
import { FormSelect } from '~/Components/Form/Select/Select';
import { SubmitButton } from '~/Components/Form/Submit';
import { FormSwitch } from '~/Components/Form/Switch';
import { AppIcon } from '~/Components/Shared/Icon/AppIcon';
import { useFeatureFlag } from '~/ui/hooks/feature-flag.hook';
import { AiAssistance } from '../../AiAssistance/AiAssistance';
import { useSms } from '../../Communication/SubmitSmsButton';
import { useDefaultCommunicationGuests } from '../../Communication/guests.hook';
import { useDurationOptions } from '../Event/Duration/duration-options.hook';
import { useReminderOptions } from '../Event/Reminder/reminder-options.hook';
import { MeetingLocation } from './Location';
import { type IFormCreateMeeting, meetingFormSchema } from './meeting-schema.hook';
import { useUpsertMeeting } from './upsert-meeting.hook';

export const UpsertMeeting: React.FC = () => {
  const { t } = useTranslation('activitiesComponent');
  const { close, opened, cardId, meeting, handleDelete, upsert } = useUpsertMeeting();
  const { cardTypes = [] } = useCardTypes();
  const isNew = !meeting?.id;
  const { card } = useCard(cardId);
  const { guests } = useDefaultCommunicationGuests({ card, type: 'MEETING' });
  const recurringActivitiesEnabled = useFeatureFlag('recurring-activities');

  const defaultValues = useMemo<IFormCreateMeeting>(
    () => ({
      date: new Date(),
      type: 'MEETING',
      reminderTimeBefore: '15min',
      duration: '30min',
      reminder: null,
      endDate: new Date(new Date().getTime() + 30 * 60000),
      guests,
      location: '',
      title: '',
      description: '',
      cardId,
      settings: {
        emailReminder: false,
        smsReminder: false,
      },
    }),
    [cardId],
  );

  const formMethods = useForm<IFormCreateMeeting>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(meetingFormSchema),
  });

  const reminderWatch = formMethods.watch('reminderTimeBefore');
  const durationWatch = formMethods.watch('duration');
  const dateWatch = formMethods.watch('date');

  const {
    reminderOptions,
    option: initReminder,
    selectedDate: reminderDate,
  } = useReminderOptions({
    reminder: meeting?.reminder,
    date: dateWatch,
    initialDate: meeting?.date,
    selectedId: reminderWatch,
  });

  const {
    durationOptions,
    option: initDuration,
    selectedDate: durationDate,
  } = useDurationOptions({
    endDate: meeting?.endDate,
    date: dateWatch,
    initialDate: meeting?.date,
    selectedId: durationWatch,
  });

  useEffect(() => {
    if (!meeting) return;
    formMethods.reset(() => {
      return {
        ...meeting,
        reminderTimeBefore: initReminder?.value ?? 'none',
        duration: initDuration?.value ?? '30min',
      };
    });
  }, [meeting, initDuration, initReminder, formMethods]);

  const onSubmit: SubmitHandler<IFormCreateMeeting> = useCallback(
    ({ reminderTimeBefore, duration, reminder, endDate, ...data }) => {
      upsert({
        reminder: reminderDate,
        endDate: durationDate,
        ...data,
      });
      close();
    },
    [upsert, reminderDate, durationDate, close],
  );

  const { canSend, reason } = useSms(card);

  return (
    <FormProvider {...formMethods}>
      <Modal
        opened={opened}
        onClose={close}
        title={
          <Stack gap={2}>
            <Text fw="500" size={'lg'}>
              {isNew ? t('Create meeting') : t('Edit meeting')}
            </Text>
            {card?.name && (
              <Text size={'sm'} c={'gray'}>
                {t('for {{name}}', { name: card?.name })}
              </Text>
            )}
          </Stack>
        }
        size={'80%'}
      >
        <Form onSubmit={() => formMethods.handleSubmit(onSubmit)()}>
          <Stack gap={'xs'}>
            <FormInput name="title" label={t('Title')} required />

            <Grid justify="flex-start" align="flex-start">
              <Grid.Col span={{ md: 7 }}>
                <Stack gap={'xs'}>
                  <Group>
                    <FormDateTimePicker
                      required
                      name="date"
                      label={t('When')}
                      dropdownType="modal"
                      leftSection={<IconClock size="1rem" />}
                    />
                    <FormSelect
                      required
                      name="duration"
                      label={t('Meeting duration')}
                      icon={<IconTimeDuration30 size="1rem" />}
                      data={durationOptions}
                    />
                    {recurringActivitiesEnabled && <RepeatEnabled />}
                  </Group>
                  <FormScheduleRepeat />
                  <MeetingLocation />
                  <FormSelect
                    name="reminderTimeBefore"
                    label={t('Reminder')}
                    icon={<IconAlarm size="1rem" />}
                    data={reminderOptions}
                    clearable
                  />
                  <FormSwitch
                    disabled={!isNew}
                    name="settings.emailReminder"
                    label={t('Send email invitations and updates')}
                    description={t(
                      'send email invitations and update on rescheduling, canceling and reminders',
                    )}
                  />
                  <Group align="baseline">
                    {reason && isNew ? (
                      <AppIcon tooltip={reason} Icon={IconAlertTriangle} color="orange" />
                    ) : null}
                    <FormSwitch
                      disabled={!canSend || !isNew}
                      name="settings.smsReminder"
                      label={t('Send SMS invitations and updates')}
                      description={t(
                        'send SMS invitations and update on rescheduling, canceling and reminders',
                      )}
                    />
                  </Group>
                  <AiAssistance />
                </Stack>
              </Grid.Col>
              <Grid.Col span={{ md: 5 }}>
                <Stack gap={'xs'}>
                  {cardId ? null : (
                    <Group>
                      <FormCardSelector
                        cardTypes={cardTypes}
                        name="cardId"
                        label={t('Select a card')}
                        description={t('Select the card to which you want to associate this meeting')}
                      />
                    </Group>
                  )}
                  <EmailGuestList cardId={cardId} name="guests" label={t('Participants')} />
                </Stack>
              </Grid.Col>
            </Grid>
            <Group justify="space-between">
              <SubmitButton>{isNew ? t('Create') : t('Update')}</SubmitButton>

              {!isNew && (
                <Button color="red" size="sm" variant="filled" onClick={handleDelete}>
                  {t('Delete')}
                </Button>
              )}
            </Group>
          </Stack>
        </Form>
      </Modal>
    </FormProvider>
  );
};
